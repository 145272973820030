import axios from '@/libs/api.request'

// =============== news-system/advertisements/list.vue =========================
export const getTableData = (to_page, per_page, searchData) => {
  return axios.request({
    url: '/api/advertisements',
    params: {
      page: to_page,
      per_page: per_page,
      search_data: JSON.stringify(searchData)
    },
    method: 'get'
  })
}

export const getInfoById = (id) => {
  return axios.request({
    url: 'api/admin/advertisements/' + id,
    method: 'get'
  })
}
