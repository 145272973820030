import axios from '@/libs/api.request'

// =============== news-system/articles/list.vue =========================
export const getNewsData = (to_page, per_page, searchData) => {
    return axios.request({
        url: '/api/news',
        params: {
            page: to_page,
            per_page: per_page,
            search_data: JSON.stringify(searchData)
        },
        method: 'get'
    })
}

export const getCategories = () => {
    return axios.request({ url: '/api/admin/categories/all', method: 'get' })
}

export const vote = (formData) => {
    return axios.request({ url: '/api/news/votenews', data: formData, method: 'post' })
}
export const collection = (formData) => {
    return axios.request({ url: '/api/news/collectnews', data: formData, method: 'post' })
}

export const edit = (id, formData) => {
    return axios.request({
        url: '/api/news/' + id,
        data: formData,
        method: 'patch'
    })
}

export const getInfoById = (id) => {
    return axios.request({
        url: 'api/news/' + id,
        method: 'get'
    })
}

export const getInfoByUserId = (id, userid) => {
    return axios.request({
        url: '/api/news/byuserid',
        data: {
            userid: userid,
            newsid: id
        },
        method: 'post'
    })
}
