<template>
    <div class="top_content">
        <div class="row">
            <div class="item">
                <cube-button style="text-align: center;padding: 17px 0px;" @click="tabClick(0)">
                    <h5 class="iconspan icon1"></h5>
                    <h5 style="font-size: 14px;">{{tabitems[0].tabname}}</h5>
                </cube-button>
            </div>
            <div class="item">
                <cube-button style="text-align: center;padding: 17px 0px;" @click="tabClick(1)">
                    <h5 class="iconspan icon2"></h5>
                    <h5 style="font-size: 14px;">{{tabitems[1].tabname}}</h5>
                </cube-button>
            </div>
            <div class="item">
                <cube-button style="text-align: center;padding: 17px 0px;" @click="tabClick(2)">
                    <h5 class="iconspan icon3"></h5>
                    <h5 style="font-size: 14px;">{{tabitems[2].tabname}}</h5>
                </cube-button>
            </div>
            <div class="item">
                <cube-button style="text-align: center;padding: 17px 0px;" @click="tabClick(3)">
                    <h5 class="iconspan icon4"></h5>
                    <h5 style="font-size: 14px;">{{tabitems[3].tabname}}</h5>
                </cube-button>
            </div>
        </div>
        <div class="row">
            <div class="item">
                <cube-button style="text-align: center;padding: 17px 0px;" @click="tabClick(4)">
                    <h5 class="iconspan icon5"></h5>
                    <h5 style="font-size: 14px;">{{tabitems[4].tabname}}</h5>
                </cube-button>
            </div>
            <div class="item">
                <cube-button style="text-align: center;padding: 17px 0px;" @click="tabClick(5)">
                    <h5 class="iconspan icon6"></h5>
                    <h5 style="font-size: 14px;">{{tabitems[5].tabname}}</h5>
                </cube-button>
            </div>
            <div class="item">
                <cube-button style="text-align: center;padding: 17px 0px;" @click="tabClick(6)">
                    <h5 class="iconspan icon7"></h5>
                    <h5 style="font-size: 14px;">{{tabitems[6].tabname}}</h5>
                </cube-button>
            </div>
            <div class="item">
                <cube-button style="text-align: center;padding: 17px 0px;" @click="tabClick(7)">
                    <h5 class="iconspan icon8"></h5>
                    <h5 style="font-size: 14px;">{{tabitems[7].tabname}}</h5>
                </cube-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'topitems',
        data () {
            return {
                tabitems: [
                    {
                        tabname: '会员捐款'
                    },
                    {
                        tabname: '项目捐款'
                    },
                    {
                        tabname: '会费缴纳'
                    },
                    {
                        tabname: '会员动态'
                    },
                    {
                        tabname: '房产交易'
                    },
                    {
                        tabname: '二手车'
                    },
                    {
                        tabname: '寻求合作'
                    },
                    {
                        tabname: '货源求购'
                    }
                ]
            }
        },
        methods: {
          tabClick (index) {
            let t = this
            switch (index) {
              case 0:
                t.$router.push({ name: 'udlist' })
                break
              case 1:
                t.$router.push({ name: 'uplist' })
                break
              case 2:
                t.$router.push({ name: 'dulist', params: { donationid: 5 } })
                break
              default:
                this.NoteTips('该功能尚未开发，敬请期待！')
                break
            }
          }
        }
    }
</script>

<style lang="stylus" rel="stylesheet/stylus">
    @import "~cm/stylus/mixin"
    @import "~cm/stylus/variable"
    .top_content
        position: relative
        overflow: hidden
        color: $color-white
        background: $color-background-ss
        .row
            width 100%
            display flex
            .item
                flex 1
                min-height 60px
                oneitem_center()
                .iconspan
                    display: inline-table;
                    width: 30px
                    height: 18px
                    background-size: 30px 18px
                    background-repeat: no-repeat
                    margin-bottom: 5px;
                .icon1
                    background-image url('兼职.svg')
                .icon2
                    background-image url('顺风车.svg')
                .icon3
                    background-image url('招聘.svg')
                .icon4
                    background-image url('生意转让.svg')
                .icon5
                    background-image url('二手房.svg')
                .icon6
                    background-image url('二手车.svg')
                .icon7
                    background-image url('联系客服_合作招商.svg')
                .icon8
                    background-image url('货源-蓝.svg')

</style>
