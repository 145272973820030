<template>
  <div class="index_content">
    <div style="">
      <vheader></vheader>
    </div>
    <cube-tab-panels v-model="indexSelectedLabelSlots" style="position: absolute;top: 50px;bottom: 60px;left:0; right:0;overflow-y: auto;padding: 2px 0;">
      <cube-tab-panel label="首页" key=1>
        <div class="ivu-row">
          <cube-slide ref="slide" v-if="adsFeeds.data.length > 0" :data="adsFeeds.data" @change="changePage">
            <cube-slide-item v-for="(item, index) in adsFeeds.data" :key="index" @click.native="clickHandler(item, index)">
              <a :href="item.link_url">
                <img :src="item.cover_image.url" style="width: 100%">
              </a>
            </cube-slide-item>
          </cube-slide>
        </div>
        <div class="ivu-row" style="margin-top: 2px;">
<!--          <div class="content-header">-->
<!--            <div class="content-header-left">-->
<!--              <h1 style="margin-left: 10px">协会动态</h1>-->
<!--            </div>-->
<!--            <div class="content-header-right">-->
<!--              <span style="font-size: .8rem;">更多</span><i class="cubeic-arrow"></i>-->
<!--            </div>-->
<!--          </div>-->
          <div class="menu-content">
            <routerItems></routerItems>
          </div>
        </div>
        <div class="ivu-row" style="margin-top: 2px;">
          <div class="content-header">
            <div class="content-header-left oneitem_leftcenter">
              <h1 style="margin-left: 10px">协会新闻</h1>
            </div>
            <div class="content-header-right">
              <span style="font-size: .8rem;">更多</span><i class="cubeic-arrow"></i>
            </div>
          </div>
          <div class="new-swipe" style="">
            <cube-index-list v-if="feeds.data.length > 0" :data="feeds.data"
                             ref="indexList"
                             :navbar="false"
                             :title="''"
                             @pulling-up="onPullingUp"
                             :pullUpLoad="feeds.last_page > feeds.current_page"
            >
              <cube-index-list-item
                v-for="(item, index) in feeds.data"
                :key="index"
                :item="item"
                :navbar="false"
                @select="selectItem"
              >
                <div @click="onItemClick(item)" class="newsItem">
                  <div class="icon">
                    <img width="60" height="60" :src="item.cover_image.url">
                  </div>
                  <div class="text">
                    <h2 class="newtitle p_1" v-html="item.title"></h2>
                    <p class="newsubtitle p_2" v-html="item.subtitle" ></p>
                  </div>
                </div>
              </cube-index-list-item>
            </cube-index-list>
            <div v-if="feeds.data.length === 0" style="width: 100%;height:100%; text-align: center;background: white;margin-top: 2px">
              <img src="../../assets/nocartdata.png" alt="" width="100" style="margin: auto; display: block;padding: 10px 0;height: 100%">
              <h2 style="margin-top: 10px;">暂时没有新闻</h2>
            </div>
<!--            <ul v-if="feeds.data.length > 0">-->
<!--              <li class="tab-panel-li" v-for="(item, index) in feeds.data" :key="index">-->
<!--                <div @click="onItemClick(item)" class="newsItem">-->
<!--                  <div class="icon">-->
<!--                    <img width="60" height="60" :src="item.cover_image.url">-->
<!--                  </div>-->
<!--                  <div class="text">-->
<!--                    <h2 class="newtitle p_1" v-html="item.title"></h2>-->
<!--                    <p class="newsubtitle p_2" v-html="item.subtitle" ></p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
          </div>
        </div>
      </cube-tab-panel>
      <cube-tab-panel label="我的" key=2>
        <div class="user_panel" style="">
          <userPanel></userPanel>
        </div>
      </cube-tab-panel>
    </cube-tab-panels>
    <cube-tab-bar v-model="indexSelectedLabelSlots" show-slider @click="clickHandler" style="border-top: 1px solid #eee;position: absolute;">
      <cube-tab icon="cubeic-home" label="首页" key=1>
      </cube-tab>
      <cube-tab icon="cubeic-person" label="我的" key=2>
      </cube-tab>
    </cube-tab-bar>
  </div>
</template>

<script>
  import {
    getNewsData
  } from '@/api/news'
  import {
    getTableData
  } from 'api/advertisement'
  import vheader from 'cp/v-header/header.vue'
  import routerItems from 'cp/topitems/topitems'
  import userPanel from '../user/user.vue'
  import { todoStorage } from 'libs/storage'
  export default {
    name: 'index',
    data () {
      return {
        indexSelectedLabelSlots: '首页',
        searchForm: {
          order_by: 'id,desc',
          category_id: parseInt(this.$route.params['cid'])
        },
        adsSearchForm: {
          order_by: 'id,desc',
          enable: 'T'
        },
        feeds: {
          data: [],
          total: 0,
          current_page: 1,
          per_page: 10
        },
        adsFeeds: {
          data: [],
          total: 0,
          enable: 'T',
          current_page: 1,
          per_page: 10
        },
        list: [],
        loading: false,
        allLoaded: false,
        wrapperHeight: 0,
        nodataurl: '../images/error-404.svg',
        nodata: '@/ast/nocartdata.png'
      }
    },
    created () {
      let t = this
      t.showLoading()
      t.getAdvertisementByPid(t.adsFeeds.current_page)
    },
    components: {
      vheader,
      routerItems,
      userPanel
    },
    methods: {
      selectItem () {
      },
      onPullingUp () {
        let t = this
        if (t.feeds.last_page > t.feeds.current_page) {
          t.feeds.current_page = t.feeds.current_page + 1
          t.getDataExcute(t.feeds.current_page)
        } else {
          t.NoteTips('没有更多数据')
        }
        t.$refs.indexList.forceUpdate()
      },
      getDataExcute (toPage) {
        let t = this
        t.feeds.current_page = toPage
        getNewsData(toPage, t.feeds.per_page, t.searchForm).then(res => {
          console.log(res)
          t.feeds.data = res.data.data
          t.feeds.total = res.data.total
          t.feeds.last_page = res.data.last_page
          t.hideLoading()
          // t.globalFancybox()
        }, function (error) {
          t.hideLoading()
          console.log(error)
        })
      },
      getAdvertisementByPid (topage) {
        let t = this
        t.adsFeeds.current_page = topage
        getTableData(topage, t.adsFeeds.per_page, t.adsSearchForm).then(res => {
          console.log(res)
          t.adsFeeds.data = res.data
          t.adsFeeds.total = res.meta.total
          t.getDataExcute(t.feeds.current_page)
          // t.globalFancybox()
        }, function (error) {
          t.hideLoading()
          console.log(error)
        })
      },
      itemClick (id) {
        let t = this
        t.$router.push({ name: 'detail', params: { itemid: id } })
      },
      loadMore () {
        this.loading = true
        setTimeout(() => {
          let last = this.list[this.list.length - 1]
          for (let i = 1; i <= 10; i++) {
            this.list.push(last + i)
          }
          this.loading = false
        }, 2500)
      },
      changePage (current) {
      },
      clickHandler (item) {
        window.scrollTo(0, 0)
        // if (item === '我的') {
        //   let userdata = todoStorage.getUserData
        //   if (userdata) {
        //   } else {
        //     // t.NoteTips('您还没有登录，请先登录')
        //     // t.$router.push({ name: 'login' })
        //   }
        // }
      },
      onItemClick (item) {
        console.log('click item:', item)
        todoStorage.setSelectNews = item
        this.$router.push({ name: 'news', params: { newsid: item.id } })
      },
      onBtnClick (btn, index) {
        if (btn.action === 'delete') {
          this.$createActionSheet({
            title: '确认要删除吗',
            active: 0,
            data: [
              { content: '删除' }
            ],
            onSelect: () => {
              this.swipeData.splice(index, 1)
            }
          }).show()
        } else {
          this.$refs.swipeItem[index].shrink()
        }
      }
    }
  }
</script>

<style lang='stylus' rel='stylesheet/stylus'>
  @import 'index.styl'
</style>
